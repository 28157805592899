export class Bezier {
  constructor (args) {
    /**
     * @type {{x: number, y: number}[]}
     * @Private
     */
    this.anchors = []
    // eslint-disable-next-line no-magic-numbers
    if (args.length < 4) {
      throw new Error('[Bezier]: the parameters should not be less than 4.')
    }
    // eslint-disable-next-line no-magic-numbers
    if (args.length % 2 !== 0) {
      throw new Error('[Bezier]: the parameter should be an even number.')
    }

    let point = {
      x: null,
      y: null
    }
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line no-magic-numbers
      if (i % 2) {
        point.y = args[i]
        this.anchors.push(point)
        point = {
          x: null,
          y: null
        }
      } else {
        point.x = args[i]
      }
    }
  }

  /**
   * 获取某一进度下，贝塞尔曲线的位置
   * @param {number} t 当前进度
   * @param {function} fn 贝塞尔曲线每一轮收敛求值时的回调，参数为产生坐标点的集合
   * @returns {{x: number, y: number}} 当前进度下的结果
   */
  update (t, fn = null) {
    let points = this.anchors
    while (points.length > 1) {
      if (typeof fn === 'function') {
        fn(points)
      }
      points = Bezier.next(points, t)
    }
    return points[0]
  }

  /**
   * 根据 `t`，求贝塞尔曲线的锚点
   * @param points
   * @param t
   * @returns {{x: number,y:number}[]}
   * @Private
   */
  static next (points, t) {
    const nextPoints = []

    for (let i = 0; i < points.length - 1; i++) {
      const startPoint = points[i]
      const endPoint = points[i + 1]
      nextPoints.push({
        x: (endPoint.x - startPoint.x) * t + startPoint.x,
        y: (endPoint.y - startPoint.y) * t + startPoint.y
      })
    }
    return nextPoints
  }
}
