export const SlideItem = {
  name: 'SlideItem',
  inject: ['Slide'],
  props: {
    properties: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  created () {
    this.Slide.slideItems.push(this)
  },
  beforeDestroy () {
    const index = this.Slide.slideItems.indexOf(this)
    if (index !== -1) {
      this.Slide.slideItems.splice(index, 1)
    }
  },
  render (createElement) {
    return createElement('div', {
      ...this.properties
    }, this.$scopedSlots?.default())
  }
}
