export class SlideHorizontal {
  setup (container, slideItems, progress = 0) {
    Object.assign(container.style, {
      display: 'flex',
    })

    this.distance = progress
    this.container = container
    this.slideItems = slideItems
    this.distanceRanges = this.slideItems.map(item => item.offsetWidth)
    this.maxDistance = this.distanceRanges.reduce((prev, next) => prev + next, 0)

    this.update(progress)

    // container.addEventListener('mousedown', mousedownEvent => {
    //
    //   const startX = mousedownEvent.pageX
    //   const oldDistance = this.distance
    //   const mouseMoveFn = mousemoveEvent => {
    //     const moveX = mousemoveEvent.pageX
    //     const distance = moveX - startX
    //     this.animate((oldDistance + distance) % this.maxDistance)
    //   }
    //   const mouseUpFn = () => {
    //     document.removeEventListener('mousemove', mouseMoveFn)
    //     document.removeEventListener('mouseup', mouseUpFn)
    //   }
    //   document.addEventListener('mousemove', mouseMoveFn)
    //   document.addEventListener('mouseup', mouseUpFn)
    // })
  }

  update (progress) {
    const index = Math.floor(progress)
    const distance = this.distanceRanges.slice(0, index).reduce((prev, next) => prev + next, 0) +
      this.distanceRanges[index] * (progress % 1)
    this.animate(-distance)
  }

  animate (distance) {
    this.distance = distance % this.maxDistance
    this.container.style.transform = `translateX(${this.distance}px)`
    const remainder = Math.abs(distance) % this.maxDistance

    let startDistance = 0
    if (this.distance <= 0) {
      for (let i = 0; i < this.distanceRanges.length; i++) {
        startDistance += this.distanceRanges[i]
        if (startDistance > remainder) {
          this.slideItems.slice(0, i).forEach(item => {
            item.style.transform = `translateX(${this.maxDistance}px)`
          })
          this.slideItems.slice(i, this.slideItems.length).forEach(item => {
            item.style.transform = 'translateX(0px)'
          })
          break
        }
      }
    } else {
      for (let i = this.distanceRanges.length - 1, min = 0; min <= i; i--) {
        startDistance += this.distanceRanges[i]
        if (startDistance >= remainder) {
          this.slideItems.slice(0, i).forEach(item => {
            item.style.transform = 'translateX(0px)'
          })
          this.slideItems.slice(i, this.slideItems.length).forEach(item => {
            item.style.transform = `translateX(${-this.maxDistance}px)`
          })
          break
        }
      }
    }
  }
}
