















































































































import { Slide, SlideItem } from '@shared/components/slide'
import { defineComponent, computed, reactive, ref, onMounted, onUnmounted } from '@vue/composition-api'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { debounce } from '@shared/utils/functions/optimize.js'
import { CarouselService } from './carousel.service'
import { ConfigService } from '@/service/config.service'

export default defineComponent({
  name: 'Carousel',
  components: {
    Slide,
    SlideItem,
  },
  setup (_, context) {
    const injector = useReflectiveInjector([CarouselService])
    const config = injector.get(ConfigService)
    const service = injector.get(CarouselService)

    const viewModel = reactive({
      slideTime: null,
      activeIndex: 4,
      videoUrl: 'https://zh-fe-public-1252921383.cos.ap-guangzhou.myqcloud.com/keniu/default.cf6c138b.mp4',
      carouselData: [
        {
          downloadId: 3,
          className: 'default',
          label: '纯净高效办公',
          downloadText: '下载可牛PDF',
          title: ['让工作更简单高效', '十二年品牌，二十年专业研发团队'],
          feature: ['无广告', '无捆绑', '千万级用户选择'],
          downloadUrl: config.downloadUrl.computer,
          report: 2,
          otherInfo: [
            {
              text: '可牛PDF',
              downloadUrl: config.downloadUrl.pdf,
              report: 1,
              downloadId: 1,
            },
            {
              text: '可牛看图',
              downloadUrl: config.downloadUrl.picture,
              report: 3,
              downloadId: 2
            },
            {
              text: '可牛压缩',
              downloadUrl: config.downloadUrl.compress,
              report: 4,
              downloadId: 4
            },
            {
              text: '可牛文件管理',
              downloadUrl: config.downloadUrl.file,
              report: 8,
              downloadId: 5
            },
          ]
        },
        {
          downloadId: 1,
          className: 'pdf',
          label: '文档阅读和编辑',
          productName: '可牛PDF',
          downloadText: '立即下载',
          title: ['核心自研', '打造高效办公方案'],
          feature: ['轻松阅读', '自由编辑', '高效转换'],
          downloadUrl: config.downloadUrl.pdf,
          report: 5,
        },
        {
          downloadId: 2,
          className: 'picture',
          label: '图片管理和美化',
          productName: '可牛看图',
          downloadText: '立即下载',
          title: ['极致的看图体验', '丰富的美化工具'],
          feature: ['纯净看图', '轻巧高效', '百变应用'],
          downloadUrl: config.downloadUrl.picture,
          report: 6,
        },
        {
          downloadId: 3,
          className: 'computer',
          label: '电脑清理优化',
          productName: '可牛清理大师',
          downloadText: '立即下载',
          title: ['专业 | 轻巧 | 快速', '系统清理优化工具'],
          feature: ['绿色安全', '高效优化', '彻底清理'],
          downloadUrl: config.downloadUrl.computer,
          report: 7,
        },
        {
          downloadId: 4,
          className: 'compress',
          label: '文件解压缩',
          productName: '可牛压缩',
          downloadText: '立即下载',
          title: ['轻巧无广告', '高效解压缩软件'],
          feature: ['免费', '速度快', '格式全'],
          downloadUrl: config.downloadUrl.compress,
          report: 9,
        },
      ],
    })
    const slide = ref(null)

    const carouselList = computed(() => {
      const arr = viewModel.carouselData
      if (isProducts.value) {
        arr.shift()
      }
      return arr
    })

    const isLastActive = computed(() => {
      return viewModel.activeIndex === 0
    })

    const isProducts = computed(() => {
      return context.root.$route.name === 'products'
    })

    const time = 100
    const handler = debounce(() => {
      if (slide.value) {
        slide.value.setup()
        slide.value.start()
      }
    }, time)

    onMounted(() => {
      init()
      window.addEventListener('resize', handler)
      service.report({ act: 1 })
    })

    onUnmounted(() => {
      autoStop()
      window.removeEventListener('resize', handler)
    })

    function init () {
      if (isProducts.value) {
        autoBegin()
      }
    }

    function isActive (index, activeIndex) {
      viewModel.activeIndex = activeIndex
      return index === activeIndex
    }

    function gotoIndex (index) {
      slide.value.gotoIndex(index)
      autoBegin()
    }

    function autoBegin () {
      autoStop()
      const time = 4000
      viewModel.slideTime = setInterval(() => {
        if (isLastActive.value) {
          autoStop()
        } else {
          slide.value.next()
        }
      }, time)
    }

    function autoStop () {
      clearTimeout(viewModel.slideTime)
    }

    function endedVideo () {
      slide.value.next()
      autoBegin()
    }

    function clickDownload (obj: any) {
      const { downloadId, report, downloadUrl } = obj

      config.downloadFile(downloadUrl)
      service.report({
        act: 2,
        click_banner: report,
        download: downloadId
      })
    }

    return {
      viewModel,
      carouselList,
      isActive,
      gotoIndex,
      endedVideo,
      autoBegin,
      autoStop,
      slide,
      clickDownload,
    }
  },
})
