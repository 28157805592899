import { CubicBezier } from '../../utils/bezier/public-api'

import { SlideHorizontal, SlideVertical, FadeInout } from './animate-types/_api'

export const Slide = {
  name: 'Slide',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    startIndex: {
      type: Number,
      default: 0
    },
    sleep: {
      type: Number,
      default: 4000
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    properties: {
      type: Object,
      default () {
        return {}
      }
    },
    reverse: {
      type: Boolean,
      default: false
    },
    animationTime: {
      type: Number,
      // eslint-disable-next-line no-magic-numbers
      default: 16 * 30
    },
    animationTimingFunction: {
      type: String,
      default: 'ease'
    },
    animationPlayer: {
      type: Object,
      default: null
    },
    cubicBezier: {
      type: Array,
      default: null
    },
    animationType: {
      type: String,
      default: 'horizontal'
    }
  },
  provide () {
    return {
      Slide: this
    }
  },
  data () {
    return {
      slideItems: [],
      animationId: null,
      sleepTimer: null,
      activeIndex: this.startIndex,
      progress: this.startIndex,
      player: null,
      canPlay: true
    }
  },
  computed: {
    frames () {
      // eslint-disable-next-line no-magic-numbers
      return Math.ceil(this.animationTime / 16)
    },
    steps () {
      const bezier = new CubicBezier(...this.animationParams)
      return Array.from({ length: this.frames }).fill(null).map((_, index) => {
        return bezier.update((index + 1) / this.frames).y
      })
    },
    animationParams () {
      return this.cubicBezier || {
        // eslint-disable-next-line no-magic-numbers
        ease: [0.25, 0.1, 0.25, 1],
        linear: [0, 0, 1, 1],
        // eslint-disable-next-line no-magic-numbers
        ios: [0.36, 0.66, 0.04, 1],
        // eslint-disable-next-line no-magic-numbers
        'ease-in': [0.42, 0, 1, 1],
        // eslint-disable-next-line no-magic-numbers
        'ease-out': [0, 0, 0.58, 1],
        // eslint-disable-next-line no-magic-numbers
        'ease-in-out': [0.42, 0, 0.58, 1],
        // eslint-disable-next-line no-magic-numbers
      }[this.animationTimingFunction] || [0.36, 0.66, 0.04, 1]
    }
  },
  mounted () {
    this.setup()
    this.start()
  },
  methods: {
    setup () {
      const container = this.$refs.slideContainer

      container.addEventListener('mouseenter', () => {
        clearTimeout(this.sleepTimer)
        this.canPlay = false
      })

      container.addEventListener('mouseleave', () => {
        this.canPlay = true
        this.start()
      })

      const items = this.slideItems.map(item => item.$el)
      if (this.animationPlayer) {
        this.player = this.animationPlayer
      } else {
        switch (this.animationType) {
          case 'horizontal':
            this.player = new SlideHorizontal()
            break
          case 'vertical':
            this.player = new SlideVertical()
            break
          case 'fade-inout':
            this.player = new FadeInout()
            break
          default:
            throw new Error('请设置正确的动画类型！')
        }
      }
      this.player.setup(container, items, this.startIndex)
    },
    start () {
      if (this.canPlay && this.autoplay) {
        clearTimeout(this.sleepTimer)
        this.sleepTimer = setTimeout(() => {
          if (this.reverse) {
            this.prev()
          } else {
            this.next()
          }
        }, this.sleep)
      }
    },
    suspend () {
      clearTimeout(this.sleepTimer)
      cancelAnimationFrame(this.animationId)
    },
    prev () {
      clearTimeout(this.sleepTimer)
      if (this.progress % 1 === 0) {
        this.animate(-1)
      }
    },
    next () {
      clearTimeout(this.sleepTimer)
      if (this.progress % 1 === 0) {
        this.animate(1)
      }
    },
    gotoIndex (index) {
      clearTimeout(this.sleepTimer)
      if (this.progress % 1 === 0) {
        this.animate(Math.abs(index) % this.slideItems.length - this.activeIndex)
      }
    },
    animate (offset) {
      if (this.slideItems.length === 0) {
        return
      }
      let step = 0
      const fn = () => {
        this.progress = (this.activeIndex + this.steps[step] * offset + this.slideItems.length) %
          this.slideItems.length
        this.player.update(this.progress)

        step++
        if (step < this.frames) {
          this.animationId = requestAnimationFrame(fn)
        } else {
          this.activeIndex = (this.activeIndex + offset + this.slideItems.length) %
            this.slideItems.length
          this.start()
        }
      }
      this.animationId = requestAnimationFrame(fn)
    }
  },
  render (createElement) {
    return createElement(this.tagName, {
      style: 'position: relative'
    }, [
      createElement('div', {
        style: 'position: relative; width: 100%; height: 100%; overflow: hidden'
      }, [
        createElement('div', {
          ref: 'slideContainer',
          style: 'width: 100%; height: 100%; z-index: 0'
        }, this.$scopedSlots.default?.())
      ]
      ),
      this.$scopedSlots.pagination?.({
        progress: this.progress,
        activeIndex: this.activeIndex
      }) || createElement('div', {
        style: 'position: absolute; left: 50%; top: 100%;' +
          ' transform: translate(-50%, -20px); display: flex;',
      }, this.slideItems.map((item, index) => createElement('span', {
        style: 'transition: 0.3s ease; width: 7px; height:' +
          ' 7px; margin: 0 3px; border-radius: 50%;' +
          (index === Math.round(this.progress) %
          this.slideItems.length ? 'background: rgba(0,0,0,.8)' : 'background: rgba(0,0,0,.3)')
      }))),
      this.$scopedSlots.toPrevController?.({
        progress: this.progress,
        activeIndex: this.activeIndex
      }),
      this.$scopedSlots.toNextController?.({
        progress: this.progress,
        activeIndex: this.activeIndex
      })
    ])
  },
  destroyed () {
    this.suspend()
  }
}
