import { Bezier } from './bezier'

export class CubicBezier extends Bezier {
  // eslint-disable-next-line max-params
  constructor (x1, y1, x2, y2) {
    super([
      0, 0,
      CubicBezier.guard0To1(x1), y1,
      CubicBezier.guard0To1(x2), y2,
      1, 1
    ])

    // eslint-disable-next-line no-magic-numbers
    this.precision = 0.000001
  }

  update (t, fn) {
    const offset = this.newton(t)
    return super.update(offset, fn)
  }

  /**
   * 牛顿法求近似值
   * @param s
   * @Private
   * @returns {number}
   */
  newton (s) {
    const t = s
    let max = 1

    while (true) {
      const point = super.update(s)
      const xDistance = point.x - t

      if (Math.abs(xDistance) < this.precision) {
        return s
      }

      let next
      if (xDistance < 0) {
        // eslint-disable-next-line no-magic-numbers
        next = (s + max) / 2
      } else {
        // eslint-disable-next-line no-magic-numbers
        next = s / 2
        max = s
      }
      s = next
    }
  }

  static guard0To1 (n) {
    if (n < 0) {
      return 0
    } else if (n > 1) {
      return 1
    }
    return n
  }
}
