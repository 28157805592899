export class FadeInout {
  setup (container, slideItems, progress = 0) {
    Object.assign(container.style, {
      position: 'relative'
    })

    this.slideItems = slideItems

    slideItems.forEach(item => {
      Object.assign(item.style, {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      })
    })

    this.update(progress)
  }

  update (progress) {
    const prevIndex = Math.floor(progress)
    const nextIndex = Math.ceil(progress) % this.slideItems.length

    const p = progress % 1

    const current = Math.round(progress) % this.slideItems.length

    this.slideItems.forEach((item, index) => {
      if (index === prevIndex) {
        Object.assign(item.style, {
          zIndex: 1,
          opacity: 1 - p
        })
      } else if (index === nextIndex) {
        Object.assign(item.style, {
          zIndex: 1,
          opacity: p
        })
      } else {
        Object.assign(item.style, {
          zIndex: 0,
          opacity: 0
        })
      }
      if (index === current) {
        Object.assign(item.style, {
          zIndex: 2
        })
      }
    })
  }
}
