/**
 * 防抖函数
 *
 * @param {Function} func 执行函数
 * @param {number} wait 延迟执行时间
 * @param {boolean} [immediate=false] immediate 是否立即执行
 * @returns {(...args) => any} new function
 */
export function debounce (func, wait, immediate = false) {
  let timeout

  return function (...args) {
    clearTimeout(timeout)

    if (immediate && !timeout) {
      func.apply(this, args)
    }

    timeout = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

/**
 * 节流函数
 *
 * @param {Function} func 执行函数
 * @param {number} wait 触发后定时执行时间
 * @param {any} [context=this] context 执行函数的上下文(this)
 * @returns {(...args) => any} new function
 */
export function throttle (func, wait, context = this) {
  let timeout = null
  let args = null

  const later = () => {
    func.apply(context, args)
    timeout = null
  }

  return function () {
    if (!timeout) {
      args = arguments
      timeout = setTimeout(later, wait)
    }
  }
}
